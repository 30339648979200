import React, { useState } from "react"
// import PropTypes from 'prop-types';

import { Grid, TextField, Button } from "@material-ui/core"
import Swal from 'sweetalert2';
import Layout from "../../components/Layout"
import { Stage, Layer, Text } from 'react-konva'
import { makeStyles } from "@material-ui/core/styles"
import ContactUsStore from "../../stores/ContactUsStore"
import SEO from "../../components/seo"

const iconAndContact = [
  {
    icon:
      `${process.env.AWS_S3_URL}/assets/iTopPlus472427719874.png`,
    type: "tel",
    contact: ["080-965-9665", "062-898-2525"],
  },
  {
    icon:
      `${process.env.AWS_S3_URL}/assets/iTopPlus861925842588.png`,
    type: "email",
    contact: ["besthavengroup@gmail.com"],
  },
  {
    icon:
      `${process.env.AWS_S3_URL}/assets/iTopPlus798587445952.png`,
    type: "line",
    contact: ["@besthavenkhaoyai"],
  },
  {
    icon:
      `${process.env.AWS_S3_URL}/assets/iTopPlus1527012246884.png`,
    type: "facebook",
    contact: ["บ้านพักเขาใหญ่ พูลวิลล่าเขาใหญ่ - Best Haven Khaoyai"],
  },
]

const useStyles = makeStyles(theme => ({
  titleContainer: {
    margin: "50px 0px 20px 0px",
  },
  title: {
    fontSize: "30px",
    fontWeight: "600",
    color: "#444444",
    textTransform: "uppercase",
    // margin: "100px",
  },
  underLineTitle: {
    borderBottom: "1px solid #444",
    width: "50%",
    margin: "5px auto",
    display: "block",
  },
  containerLeft: {
    textAlign: "left",
  },
  itemInLeft: {
    marginTop: "5px",
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
  },
  companyName: {
    fontSize: "28px",
    fontWeight: "600",
    color: "#444444",
  },
  iconStyle: {
    width: "40px",
  },
  wathcing: {
    backgroundColor: "red",
  },
  // wathcing2: {
  //   backgroundColor: "green",
  // },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    "& label": {
      fontFamily: `Kanit`,
    },
  },
  textFieldCaptCha: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: "15ch",
  },
  label: {
    fontSize: "15px",
    lineHeight: "1.42857143",
    color: "#333",
    fontWeight: "bold",
    textAlign: "right",
    // paddingRight: "15px",
  },
  itemInRight: {
    marginTop: "15px",
    width: "100%",
  },
  linkColor: {
    transition: "0.3s",
    color: "#444",
    fontSize: "17px",
    "&:hover": {
      color: "#e2c366 !important",
      textDecoration: "none",
    },
  },
  captchaContainer: {
    // backgroundImage:
    //   "linear-gradient(to top, lightgrey 0%, lightgrey 1%, #e0e0e0 26%, #efefef 48%, #d9d9d9 75%, #bcbcbc 100%)",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "16ch",
    height: "50px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonSubmit: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  refreshCaptcha: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: "11px",
    fontWeight: "600",
    textDecoration: "none",
    cursor: "pointer",
    color: "#000",
    "&:hover": {
      color: "#ccd5e3 !important",
    },
  },
}))

const renderIconAndContact = classes => {
  return iconAndContact.map((item, index) => {
    return (
      <Grid key={index} container justifyContent="flex-start">
        <Grid item xs={2} lg={1}>
          <div className={`${classes.itemInLeft}`}>
            <img
              className={classes.iconStyle}
              original="30"
              src={item.icon}
              alt=""
            />
          </div>
        </Grid>
        <Grid item xs={10} lg={11}>
          <div className={`${classes.itemInLeft}`}>
            <div>
              {item.contact.map((text, index2) => {
                const link =
                  item.type === "tel"
                    ? `tel:${text}`
                    : item.type === "email"
                      ? `mailto:${text}`
                      : item.type === "line"
                        ? `http://line.me/ti/p/~${text}`
                        : item.type === "facebook"
                          ? `https://th-th.facebook.com/besthavenkhaoyai/`
                          : `#`
                return (
                  <a
                    className={classes.linkColor}
                    href={link}
                    key={index2}
                    target="_blank"
                    style={{ display: "block" }}
                    rel="noopener noreferrer"
                  >
                    {text}
                  </a>
                )
              })}
            </div>
          </div>
        </Grid>
      </Grid>
    )
  })
}

const randomNoFiveDigit = () => {
  let result = ""
  for (let i = 0; i < 5; i++) {
    result += `${Math.floor(Math.random() * 10)}`
  }
  return result
}

const _onSubmit = async (name, email, tel, title, detail, captchaInput, randomNo) => {
  // eslint-disable-next-line
  const emailValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
  // eslint-disable-next-line
  const mobileValidation = /^\d{10}$/.test(tel)
  if (!name) return
  if (!emailValidation) return
  if (!mobileValidation) return
  if (!title) return
  if (!detail) return
  if (captchaInput !== randomNo) return
  const result = await ContactUsStore.saveContactUs({ name, email, tel, title, detail })
  return result
}

const popupStatus = async (status, title, text) => {
  await Swal.fire({
    icon: status,
    title: title,
    text: text,
  })
}

// const makeGradient = () => {
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');

//   let gradient = ctx.createLinearGradient(0, 0, 100, 100);
//   gradient.addColorStop(0.00, 'lightgrey');
//   gradient.addColorStop(0.10, 'lightgrey');
//   gradient.addColorStop(0.26, '#e0e0e0');
//   gradient.addColorStop(0.48, '#efefef')
//   gradient.addColorStop(0.75, '#d9d9d9');
//   gradient.addColorStop(1.00, '#bcbcbc');
//   return gradient
// }

const ContactUs = () => {
  const classes = useStyles()
  const iconAndContact = renderIconAndContact(classes)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [title, setTitle] = useState("")
  const [textMessage, setTextMessage] = useState("")
  const [captchaInput, setCaptchaInput] = useState("")
  const [randomNo, reRandom] = useState(randomNoFiveDigit())
  const imgShare = `${process.env.AWS_S3_URL}/assets/share-social-new.jpg`
  return (
    <Layout page="contactUs" showContactUs={false}>
      <SEO
          title="BEST HAVEN POOLVILLA"
          description="แหล่งรวมบ้านพักพูลวิลล่าพร้อมสระว่ายน้ำส่วนตัว
    และบ้านพักตากอากาศที่มากที่สุดและราคาดีที่สุดในโซนเขาใหญ่
    และบริเวณใกล้เคียงโดยสามารถจองผ่านทางเราโดยตรงได้ทันที"
          image={imgShare}
        />
      <Grid container className={classes.titleContainer} justifyContent="center">
        <Grid item>
          <span className={classes.title}>ติดต่อเรา</span>
          <br />
          <div className={classes.underLineTitle}></div>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        className={classes.itemContainer}
      >
        <Grid item lg={7} md={6} xs={12}>
          <div className={`${classes.containerLeft}`}>
            <span className={classes.companyName}>
              บ้านพักเขาใหญ่ พูลวิลล่าเขาใหญ่
              </span>
          </div>
          <div className={`${classes.containerLeft}`}>{iconAndContact}</div>
          <div className={`${classes.containerLeft}`}>
            <div
              style={{
                width: "100%",
                maxWidth: "560px",
                // backgroundColor: "red",
                margin: "40px 0px 40px 0px",
              }}
            >
              <img src={`${process.env.AWS_S3_URL}/assets/iTopPlus1094706405125.png`} alt="about" />
            </div>
          </div>
        </Grid>
        <Grid item lg={5} md={6} xs={12}>
          <Grid container justifyContent="flex-start">
            <div className={classes.itemInRight}>
              <Grid container justifyContent="flex-start">
                <Grid item lg={2} md={2} xs={3} className={classes.label}>
                  <label htmlFor="inputName">ชื่อ :</label>
                </Grid>
                <Grid item lg={10} md={10} xs={9}>
                  <TextField
                    value={name}
                    type="text"
                    size="small"
                    label="ชื่อติดต่อกลับ.."
                    id="inputName"
                    className={classes.textField}
                    variant="outlined"
                    onChange={e => setName(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.itemInRight}>
              <Grid container justifyContent="flex-start">
                <Grid item lg={2} md={2} xs={3} className={classes.label}>
                  <label htmlFor="inputEmail">อีเมล :</label>
                </Grid>
                <Grid item lg={10} md={10} xs={9}>
                  <TextField
                    value={email}
                    type="email"
                    size="small"
                    label="อีเมลติดต่อกลับ.."
                    id="inputEmail"
                    className={classes.textField}
                    variant="outlined"
                    onChange={e => setEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.itemInRight}>
              <Grid container justifyContent="flex-start">
                <Grid item lg={2} md={2} xs={3} className={classes.label}>
                  <label htmlFor="inputMobile">เบอร์โทร :</label>
                </Grid>
                <Grid item lg={10} md={10} xs={9}>
                  <TextField
                    value={mobile}
                    type="tel"
                    size="small"
                    label="เบอร์โทรติดต่อ.."
                    id="inputMobile"
                    className={classes.textField}
                    variant="outlined"
                    onChange={e => setMobile(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.itemInRight}>
              <Grid container justifyContent="flex-start">
                <Grid item lg={2} md={2} xs={3} className={classes.label}>
                  <label htmlFor="inputTitle">หัวเรื่อง :</label>
                </Grid>
                <Grid item lg={10} md={10} xs={9}>
                  <TextField
                    value={title}
                    type="text"
                    size="small"
                    label="เรื่องที่ติดต่อ.."
                    id="inputTitle"
                    className={classes.textField}
                    variant="outlined"
                    onChange={e => setTitle(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.itemInRight}>
              <Grid container justifyContent="flex-start">
                <Grid item lg={2} md={2} xs={3} className={classes.label}>
                  <label htmlFor="inputDetail">ข้อความ :</label>
                </Grid>
                <Grid item lg={10} md={10} xs={9}>
                  <TextField
                    value={textMessage}
                    type="text"
                    label="รายละเอียด.."
                    id="inputDetail"
                    multiline
                    minRows="4"
                    className={classes.textField}
                    variant="outlined"
                    onChange={e => setTextMessage(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.itemInRight}>
              <Grid container justifyContent="flex-start">
                <Grid item lg={2} md={2} xs={3} className={classes.label}>
                  <label htmlFor="inputCapcha">รหัสภาพ :</label>
                </Grid>
                <Grid item lg={10} md={10} xs={9}>
                  <TextField
                    value={captchaInput}
                    type="text"
                    size="small"
                    label="กรอกรหัส.."
                    id="inputCapcha"
                    className={classes.textFieldCaptCha}
                    variant="outlined"
                    onChange={e => setCaptchaInput(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.itemInRight}>
              <Grid container justifyContent="flex-start">
                <Grid item lg={2} md={2} xs={3} className={classes.label}></Grid>
                <Grid item lg={10} md={10} xs={9}>
                  <div className={classes.captchaContainer}>
                    <Stage width={160} height={50}>
                      <Layer>
                        {/* <Rect
                          x={0}
                          y={0}
                          width={200}
                          height={50}
                          fill={makeGradient()}
                          shadowBlur={5}
                        /> */}
                        <Text
                          x={46}
                          y={18}
                          text={randomNo}
                          fill='#1a6aeb'
                          align='center'
                          fontVariant={800}
                          fontSize={20}
                        />
                      </Layer>
                    </Stage>
                  </div>
                  <div>
                    <button
                      className={classes.refreshCaptcha}
                      onClick={(e) => reRandom(randomNoFiveDigit())}
                    >
                      เปลี่ยนรหัสภาพ
                      </button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={classes.itemInRight}>
              <Grid container justifyContent="flex-start">
                <Grid item lg={2} md={2} xs={3} className={classes.label}></Grid>
                <Grid item lg={10} md={10} xs={9}>
                  <Button
                    size="small"
                    variant="contained"
                    className={classes.buttonSubmit}
                    onClick={async () => {
                      const result = await _onSubmit(name, email, mobile, title, textMessage, captchaInput, randomNo)
                      if (result) {
                        setName("")
                        setEmail("")
                        setMobile("")
                        setTitle("")
                        setTextMessage("")
                        setCaptchaInput("")
                        reRandom(randomNoFiveDigit())
                        await popupStatus('success', 'ข้อมูลถูกส่ง', 'ส่งแล้วจ้า')
                      } else {
                        reRandom(randomNoFiveDigit())
                        await popupStatus('error', 'ผิดพลาด', 'กรุณาตรวจสอบข้อมูลทั้งหมดให้ครบและถูกต้องหรือไม่ ?')
                      }
                    }}
                  >
                    ส่งข้อมูล
                    </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ContactUs

